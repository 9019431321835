export const CrossIcon = ({
    size = "22",
    color = "currentColor",
    style,
}: {
    size?: string | number;
    color?: string;
    style?: React.CSSProperties;
}) => (
    <svg width={size} height={size} style={style} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.3099 17.3374C19.9911 18.0219 20.3589 18.7474 19.487 19.6235C18.615 20.4997 17.7567 19.5688 17.4298 19.2266L10.1409 11.9028L2.68862 19.4045C2.34802 19.7467 1.27172 20.4449 0.46791 19.6372C-0.335905 18.8296 0.00469476 18.0493 0.67227 17.3785L8.02922 9.98631L0.754014 2.68994C0.0864386 2.00548 -0.213289 1.19781 0.46791 0.513347C1.14911 -0.157426 1.96655 0.116359 2.64775 0.787132L10.1137 7.87817L17.4434 0.513347C18.1246 -0.171116 18.8194 -0.171116 19.487 0.513347C20.1682 1.19781 19.8685 2.00548 19.2009 2.68994L11.9665 9.95893L19.3235 17.3511L19.3099 17.3374Z"
            fill={color}
        />
    </svg>
);
